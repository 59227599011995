import React, { Component, Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import classnames from "classnames";
import "./css/combat.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BATTLE from "../../ABI/BattleContract.json";
import ABI from "../../ABI/DefSpaceCharacters.json";
// Components
import cardBg1 from "../../assets/images/card-bg-1.png";
import cardBg from "../../assets/images/card-bg.png";
import cardBg4 from "../../assets/images/card-bg-4.png";
import cardBg3 from "../../assets/images/card-bg-3.png";
import cardBgv2 from "../../assets/images/card-bg-v2.png";
import cardNameBoard from "../../assets/images/card-name-board.png";
import cardMonster1 from "../../assets/images/card-monster-1.png";
import cardMonster4 from "../../assets/images/card-monster-4.png";
import cardMonster3 from "../../assets/images/card-monster-3.png";
import cardMonster from "../../assets/images/card-monster.png";
import cardMagic from "../../assets/images/card-magic.png";
import cardBase from "../../assets/images/card-base.png";
import cardMonster5 from "../../assets/images/card-monster-5.png";
import cardBasev2 from "../../assets/images/card-base-v2.png";
import cardFace from "../../assets/images/card-face.png";
import cardNameBoard2 from "../../assets/images/card-name-board-v2.png";
import cardNameBoard5 from "../../assets/images/card-name-board-v5.png";
import powerIcon from "../../assets/images/power-icon.png";
import accuracyIcon from "../../assets/images/accuracy-icon.png";
import starIcon from "../../assets/images/star-icon.png";
import shield from "../../assets/images/shield.png";
import sliderPrevious from "../../assets/images/slider-left-arrow.png";
import sliderNext from "../../assets/images/slider-right-arrow.png";
import cardTriangle2 from "../../assets/images/card-triangle-2.png";
import cardBgV34 from "../../assets/images/card-bg-v3-4.png";
import cardNameBoardV3 from "../../assets/images/card-name-board-v3.png";
import cardNameBoardV331 from "../../assets/images/card-base-v3-3-1.png";
import catCoinLogo from "../../assets/images/cat_coin_new.png";
import cardMonster6 from "../../assets/images/card-monster-6.png";
import cardFace3 from "../../assets/images/card-face-3.png";
import cardTriangle1 from "../../assets/images/card-triangle-1.png";
import { Battle_contract, Character_contract, charImages } from "../../common/Constant";
import { CHAR_NAME } from "../../helpers/constant";
import common from "../../assets/images/common_mini.png";
import uncommon from "../../assets/images/uncommon_mini.png";
import rare from "../../assets/images/rare_mini.png";

const Web3 = require("web3");
const web3 = new Web3(window.ethereum);

const Home = (props) => {
  const [key, setKey] = useState();
  const [array, setArray] = useState([]);
  const [check, setCheck] = useState(false);
  const [index, setIndex] = useState();
  const [currentCell, setCurrentCell] = useState(0);
  const [currentEnemy, setCurrentenemy] = useState(0);
  const [claimDate, setClaimDate] = useState();
  const [claimReward, setClaimReward] = useState(0);
  const [accuracy, setAccuracy] = useState([]);
  const [showPercentage, setShowPercentage] = useState([]);
  const [attackStacks, setAttackStacks] = useState([]);
  const [timer, setTimer] = useState();
  const [characterData, setCharacterData] = useState([]);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const [isLoading, setLoading] = useState(false);
  const [combatReward, setCombatReward] = useState(0);

  const [enemyAccuray, setEnemies] = useState([]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <img src={sliderNext} />,
    prevArrow: <img src={sliderPrevious} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
          className: "center",
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
          className: "center",
          centerMode: true,
        },
      },
    ],
  };
  var settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    className: "center",
    centerMode: true,
    nextArrow: <img src={sliderNext} />,
    prevArrow: <img src={sliderPrevious} />,
    afterChange: (current) => { setCurrentCell(current); },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
          className: "center",
          centerMode: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
          className: "center",
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
          className: "center",
          centerMode: true,
        },
      },
    ],
  };
  var settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={sliderNext} />,
    prevArrow: <img src={sliderPrevious} />,
    afterChange: (current) => { setCurrentCell(current); },
  };

  const connectMetaMask = async () => {
    await window.ethereum.enable();
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const accounts = await web3.eth.getAccounts();
    var balance = await web3.eth.getBalance(accounts[0]);
    localStorage.setItem("metaAddress", JSON.stringify(accounts[0]));
    balance = await (balance / Math.pow(10, 18)).toFixed(4);

    window.location.reload();
  };

  const disconnectMetaMask = () => {
    localStorage.clear();
    window.location.reload();
  };

  //Level 1
  

  var create_contract = new web3.eth.Contract(BATTLE, Battle_contract);
  var create_defpace_contract = new web3.eth.Contract(ABI, Character_contract);
  var address = JSON.parse(localStorage.getItem("metaAddress"));

  useEffect(() => {
    
    if(enemyAccuray.length == 0) {
      reloadEnemies();
    }


    let address = localStorage.getItem("metaAddress");

    if (address !== null && address !== "undefined") {
      details();
      claimData();
      timeAgain();
    }
  }, []);

  const reloadEnemies = () => {
    var l1 = Math.random() * (15 - 30) + 30;
    var l1p = parseInt(l1);
  
    //Level 2
    var l2 = Math.random() * (31 - 40) + 40;
    var l2p = parseInt(l2);
  
    //Level 3
    var l3 = Math.random() * (46 - 60) + 60;
    var l3p = parseInt(l3);
  
    //Level 4
    var l4 = Math.random() * (75 - 80) + 80;
    var l4p = parseInt(l4);
    var temp = [l1p, l2p, l3p, l4p];
    setEnemies(temp);
  }

  const details = async () => {
    create_contract.methods.userInfo(address).call((err, res) => {
      var totalIndex = res._charIndexRoaster;
      setIndex(totalIndex);
      var initial = 0;
      var array1 = [];
      var stack1 = [];
      var characterData1 = [];
      var accuracy1 = [];

      for (let i = 1; i <= totalIndex; i++) {
        create_contract.methods
          .getRoasterInfo(address, i)
          .call(async (err, resp) => {
            //console.log(resp);
            array1.push(resp._keyID);
            stack1.push(resp._onRoaster && resp._coolDown != 0 ? resp._attackStacks : 0);

            create_defpace_contract.methods
              .charInfo(resp._keyID)
              .call((err, res) => {
                //console.log(res);
                accuracy1.push(res.baseAccuracy);
                res.id = resp._keyID;
                res.rosterInfo = resp;
                res.roasterIndex = i;
                characterData1.push(res);
                console.log(res);
              });

            if (i === totalIndex - 1) {
              setCheck(true);
            }
            if (initial === 0) {
              for (let j = 0; j < totalIndex; j++) {
                if (resp._keyID > enemyAccuray[j]) {
                  let atk =
                    (1 -
                      enemyAccuray[j] /
                        (2.4 * (2 * resp._keyID - enemyAccuray[j]))) *
                    100;

                  let atk_based = Math.round(atk);
                  setShowPercentage((data) => [...data, atk_based]);
                }
                if (enemyAccuray[j] > resp._keyID) {
                  let def = (resp._keyID / (2 * enemyAccuray[j] + 2)) * 100;

                  let def_based = Math.round(def);
                  setShowPercentage((data) => [...data, def_based]);
                }
              }
              initial++;
              function settingfirstdata() {
                setKey(resp._keyID);
              }
              setTimeout(settingfirstdata, 1000);
            }
          });
      }
      setCharacterData(characterData1);
      setKey(characterData1[0]);
      setAccuracy(accuracy1);
      setArray(array1);
      setAttackStacks(stack1);
    });
  };

  const combat = async(num) => {
    if(currentCell < 0) {
      alert("Please select a character.");
      return;
    }
    setLoading(true);
    setCurrentenemy(num);
    let value = 0;
    let BNB = Web3.utils.toWei(value.toString(), "ether");

    var hasAttack = false;
    console.log(characterData[currentCell]);
    await create_contract.methods.getRoasterInfo(address, characterData[currentCell].roasterIndex).call(async (errRoasterInfo, resRoasterInfo) => {
      if(errRoasterInfo) {
        return;
      }
       console.log(resRoasterInfo);
      hasAttack = resRoasterInfo._coolDown != 0 && resRoasterInfo._onRoaster;
      if(!hasAttack) {
        alert("No attacks")
        setLoading(false);
        return;
      }
      create_defpace_contract.methods.charInfo(characterData[currentCell].id).call((err, res) => {
        create_defpace_contract.methods.charInfo(num).call((err, resp) => {
          //SIGNATURE_PERMIT_TYPEHASH()
          create_contract.methods
            .SIGNATURE_PERMIT_TYPEHASH()
            .call((err, typehash) => {
              create_contract.methods.userInfo(address).call((err, nonces) => {
                let nonc = nonces.nonces;
                let Nonces = +nonc;
                let Nonce = Nonces + 1;
  
                let deadline = new Date().getTime() + 60000;
  
                create_contract.methods.chainId().call(async (err, chain) => {
                  let hash1 = web3.utils.keccak256(web3.utils.encodePacked(
                    typehash,
                    characterData[currentCell].id,
                    num,
                    address,
                    res.baseAccuracy * res.level,
                    res.skillValue,
                    res.power,
                    chain,
                    Nonce,
                    deadline
                  ));
                  let signature = await web3.eth.personal.sign(hash1, address);
  
                  //Combat method
                  create_contract.methods
                    .combat([
                      [characterData[currentCell].id, address, res.baseAccuracy * res.level],
                      [
                        num,
                        "0x02c6Ef18467F48EdEC5047e3b105f90e2F79c6dd",
                        enemyAccuray[num - 15],
                      ],
                      res.skillValue,
                      res.power,
                      deadline,
                      signature,
                    ])
                    .send({
                      from: address,
                      value: BNB,
                    })
                    .on("error", function (error) {
                      setLoading(false);
                    })
  
                    .on("receipt", function (receipt) {
                    })
  
                    .then(function (newContractInstance) {
                      setCombatReward(
                        Math.round(newContractInstance.events.Combat.returnValues._reward * 100/10000000000)/100
                      );
                      /*setClaimReward(
                        newContractInstance.events.Combat.returnValues._reward
                      );*/
  
                      //let Final_Defence = resp.baseAccuracy;
                      //let Final_Accuracy = res.baseAccuracy;
  
                      var winRate = showPercentage[num - 15];
                      var rand = Math.random() * (0 - 100) + 100
  
                      setLoading(false);
                      if (rand <= winRate) {
                        toggle1();
                        claimData();
                      }
                      //Enemy character is higher than the users Final Accuracy(Winning % decrease)
                      else {
                        toggle2();
                      }
                      reloadEnemies();
                    });
                });
              });
            });
        });
      });
    });
  };

  var newArray = array.slice(0, index);

  var sorted = newArray.sort(function (a, b) {
    return a - b;
  });

  var newAttackStacks = attackStacks.slice(0, index);

  var characterInfo = characterData.slice(0, index);

  useEffect(() => {
    setShowPercentage([]);
    if (currentCell >= 0) {
      setKey(currentCell);

      for (let i = 0; i < index; i++) {
        if (accuracy[currentCell] > enemyAccuray[i]) {
          let atk =
            (1 -
              enemyAccuray[i] /
                (2.4 * (2 * accuracy[currentCell] - enemyAccuray[i]))) *
            100;

          let atk_math = Math.round(atk);

          setShowPercentage((data) => [...data, atk_math]);
        }
        if (enemyAccuray[i] > accuracy[currentCell]) {
          let def = (accuracy[currentCell] / (2 * enemyAccuray[i] + 2)) * 100;

          let def_math = Math.round(def);

          setShowPercentage((data) => [...data, def_math]);
        }
      }
    }
  }, [currentCell]);

  const claim = () => {
    if (claimReward == 0) {
      alert("No reward it's to be claimed");
    } else {
      create_contract.methods
        .claim()
        .send(
          {
            from: address,
            value: 0,
          },
          function (error, transactionHash) {
            
          }
        )
        .on("error", function (error) {
        })
        .on("transactionHash", function (transactionHash) {
        })
        .on("receipt", function (receipt) {
        })
        .on("confirmation", function (confirmationNumber, receipt) {})
        .then(function (newContractInstance) {
          claimData();
          timeAgain();
        });
    }
  };

  const claimData = () => {
    create_contract.methods.userInfo(address).call((err, res) => {
      if (res) {
        var avlClaimAmt = Math.round(res[1] * 100/(10000000000))/100;
        var unixTimestamp = res[2];
        var date = new Date(unixTimestamp * 1000);

        if (unixTimestamp <= 0) {
          setClaimDate("Need Claim");
        } else {
          var day = date.getDate();
          var month = date.getMonth();
          var year = date.getFullYear();
          var fullDate = day + "/" + (month + 1) + "/" + year
          setClaimDate(fullDate);
        }
        setClaimReward(avlClaimAmt);
      }
    });
  };

  const timeAgain = () => {
    create_contract.methods.userInfo(address).call((err, res) => {
      var lastClaimDate = parseInt(res._lastClaim);
      if (res._lastClaim == 0) {
        setTimer("No time");
      } else {
        var todayDate = Math.round(Date.now() / 1000);
        let newTime = 604800 + lastClaimDate;
        var timeLeft = newTime - todayDate;

        var time = setInterval(function timeInfo() {
          timeLeft = timeLeft - 1;
          if (timeLeft === 0 || timeLeft < 0) {
            setTimer("Claim Now");
            return;
          }
          var d = Math.floor(timeLeft / (3600 * 24));
          var h = Math.floor((timeLeft % (3600 * 24)) / 3600);
          var m = Math.floor((timeLeft % 3600) / 60);
          var s = Math.floor(timeLeft % 60);

          var dDisplay = d === 0 || d > 0 ? d + "d " : "";
          var hDisplay = h === 0 || h > 0 ? h + "h " : "";
          var mDisplay = m === 0 || m > 0 ? m + "m " : "";
          var sDisplay = s === 0 || s > 0 ? s + "s " : "";
          setTimer(dDisplay + hDisplay + mDisplay + sDisplay);
        }, 1000);
      }
    });
  };

  return (
    <Fragment>
      {isLoading ?
        <div className="loadingScreen">
          <img src={catCoinLogo} className="loadingCoin"/>
          <img src={charImages(characterData[currentCell])} className="hero"/>
          <img src={"../../assets/images/card-monster-" + (currentEnemy - 14) + ".png"} className="enemy"/>
        </div> : null 
      }
      <div className="home-container">
        <div className="homeTopcnt d-flex justify-content-center flex-column-reverse flex-md-row justify-content-md-between">
          <div className="topLeft-cnt">
            <p>
              <span className="org-txt">Last Claim: </span>
              {claimDate}
            </p>
            <p>
              <span className="grn-txt">Next Claim: </span>
              {timer}
            </p>
          </div>
          <div className="topRight-cnt d-flex justify-content-between align-items-center">
            <div className="rewardsCounter mr-3">
              <span className="grn-txt">Reward: </span>
              {claimReward}
            </div>
            <Modal isOpen={modal1} toggle={toggle1} className="win-modal">
              <ModalBody>
                <h1 className="text-center">Congratulations</h1>
                <h3 className="text-center">You Win {combatReward} DVERSE!</h3>
              </ModalBody>
            </Modal>
            <Modal isOpen={modal2} toggle={toggle2} className="lose-modal">
              <ModalBody>
                <h3 className="text-center">You Lose!</h3>
              </ModalBody>
            </Modal>

            <button className="btn claim-btn" onClick={claim}>
              Claim Reward
            </button>
            {currentCell}
            {address === null || address === "undefined" ? (
              <button className="btn claim-btn" onClick={connectMetaMask}>
                Connect
              </button>
            ) : (
              <button className="btn claim-btn " onClick={disconnectMetaMask}>
                {address.slice(0, 5) + "....." + address.slice(-5)}
              </button>
            )}
          </div>
        </div>

        <Container>
          <Slider {...settings}>
            <div className="mons-card-cnt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="1186"
                height="1969"
                viewBox="0 0 1186 1969"
              >
                <defs>
                  <image id="cardBg-1" href={cardBg1}></image>
                </defs>
                <use className="pntNo" x="126" y="235" xlinkHref="#cardBg-1" />
                <image
                  href={cardMonster1}
                  x="296"
                  y="648"
                  width="634"
                  height="626"
                ></image>
                <path
                  className="def-board"
                  d="M973.07,1716.99l-727.566-.3-28.5-58.99,28.938-58.7,727.566,0.3,28.5,59Z"
                />
                <text className="def-txt" x="462.767" y="1675.607">
                  Defense: {enemyAccuray[0]}({showPercentage[0]}%)
                </text>
                <image
                  className="pntNo"
                  x="162"
                  y="1562"
                  href={shield}
                  width="192"
                  height="186"
                ></image>
                <image
                  x="119"
                  y="227"
                  width="942"
                  height="306"
                  href={cardNameBoard5}
                ></image>
                <g>
                  <text
                    className="cls-plName"
                    transform="translate(213 389.708) scale(1.6 1.599)"
                  >
                    Aluntian
                  </text>
                  <text
                    className="cls-ntName"
                    x="948.911"
                    y="480.785"
                    textAnchor="end"
                  >
                    Universe: Eirian
                  </text>
                  <text className="cls-mntName" x="211.911" y="480.785">
                    Goblin
                  </text>

                  <image
                    className="d-none"
                    x="674"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    className="d-none"
                    x="755"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    className="d-none"
                    x="836"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="916"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                </g>
                <image
                  className="pntNo"
                  href={cardBase}
                  width="1186"
                  height="1969"
                  x="0"
                  y="0"
                ></image>
              </svg>
              <button
                className="btn fight-btn"
                //disabled={key ? false : true}
                onClick={() => combat(15)}
              >
                Fight!!!
              </button>
            </div>
            <div className="mons-card-cnt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="1186"
                height="1969"
                viewBox="0 0 1186 1969"
              >
                <defs>
                  <image id="cardBg" href={cardBg}></image>
                </defs>
                <use className="pntNo" x="126" y="235" xlinkHref="#cardBg" />
                <image
                  href={cardMonster}
                  x="234"
                  y="593"
                  width="725"
                  height="748"
                ></image>
                <path
                  className="def-board"
                  d="M973.07,1716.99l-727.566-.3-28.5-58.99,28.938-58.7,727.566,0.3,28.5,59Z"
                />
                <text className="def-txt" x="462.767" y="1675.607">
                  Defense: {enemyAccuray[1]}({showPercentage[1]}%)
                </text>
                <image
                  className="pntNo"
                  x="162"
                  y="1562"
                  href={shield}
                  width="192"
                  height="186"
                ></image>
                <image
                  x="119"
                  y="227"
                  width="942"
                  height="306"
                  href={cardNameBoard5}
                ></image>
                <g>
                  <text
                    className="cls-plName"
                    transform="translate(213 389.708) scale(1.6 1.599)"
                  >
                    Lason
                  </text>
                  <text
                    className="cls-ntName"
                    x="948.911"
                    y="480.785"
                    textAnchor="end"
                  >
                    Universe: Eirian
                  </text>
                  <text className="cls-mntName" x="211.911" y="480.785">
                    Imp
                  </text>

                  <image
                    className="d-none"
                    x="674"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    className="d-none"
                    x="755"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="836"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="916"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                </g>
                <image
                  className="pntNo"
                  href={cardBase}
                  width="1186"
                  height="1969"
                  x="0"
                  y="0"
                ></image>
              </svg>

              <button
                className="btn fight-btn"
                //disabled={key ? false : true}
                onClick={() => combat(16)}
              >
                Fight!!!
              </button>
            </div>
            <div className="mons-card-cnt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="1186"
                height="1969"
                viewBox="0 0 1186 1969"
              >
                <defs>
                  <image id="cardBg-3" href={cardBg3}></image>
                </defs>
                <use className="pntNo" x="126" y="235" xlinkHref="#cardBg-3" />
                <image
                  href={cardMonster3}
                  x="106"
                  y="604"
                  width="1002"
                  height="814"
                ></image>
                <path
                  className="def-board"
                  d="M973.07,1716.99l-727.566-.3-28.5-58.99,28.938-58.7,727.566,0.3,28.5,59Z"
                />
                <text className="def-txt" x="462.767" y="1675.607">
                  Defense: {enemyAccuray[2]}({showPercentage[2]}%)
                </text>
                <image
                  className="pntNo"
                  x="162"
                  y="1562"
                  href={shield}
                  width="192"
                  height="186"
                ></image>
                <image
                  x="119"
                  y="227"
                  width="942"
                  height="306"
                  href={cardNameBoard5}
                ></image>
                <g>
                  <text
                    className="cls-plName"
                    transform="translate(213 389.708) scale(1.6 1.599)"
                  >
                    Sisilim
                  </text>
                  <text
                    className="cls-ntName"
                    x="948.911"
                    y="480.785"
                    textAnchor="end"
                  >
                    Universe: Eirian
                  </text>
                  <text className="cls-mntName" x="211.911" y="480.785">
                    Ewes
                  </text>

                  <image
                    className="d-none"
                    x="674"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="755"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="836"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="916"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                </g>
                <image
                  className="pntNo"
                  href={cardBase}
                  width="1186"
                  height="1969"
                  x="0"
                  y="0"
                ></image>
              </svg>

              <button
                className="btn fight-btn"
               // disabled={key ? false : true}
                onClick={() => combat(17)}
              >
                Fight!!!
              </button>
            </div>
            <div className="mons-card-cnt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="1186"
                height="1969"
                viewBox="0 0 1186 1969"
              >
                <defs>
                  <image id="cardBg-4" href={cardBg4}></image>
                </defs>
                <use className="pntNo" x="126" y="235" xlinkHref="#cardBg-4" />
                <image
                  href={cardMonster4}
                  x="76"
                  y="495"
                  width="1069"
                  height="962"
                ></image>
                <path
                  className="def-board"
                  d="M973.07,1716.99l-727.566-.3-28.5-58.99,28.938-58.7,727.566,0.3,28.5,59Z"
                />
                <text className="def-txt" x="462.767" y="1675.607">
                  Defense: {enemyAccuray[3]}({showPercentage[3]}%)
                </text>
                <image
                  className="pntNo"
                  x="162"
                  y="1562"
                  href={shield}
                  width="192"
                  height="186"
                ></image>
                <image
                  x="119"
                  y="227"
                  width="942"
                  height="306"
                  href={cardNameBoard5}
                ></image>
                <g>
                  <text
                    className="cls-plName"
                    transform="translate(213 389.708) scale(1.6 1.599)"
                  >
                    Glacio
                  </text>
                  <text
                    className="cls-ntName"
                    x="948.911"
                    y="480.785"
                    textAnchor="end"
                  >
                    Universe: Eirian
                  </text>
                  <text className="cls-mntName" x="211.911" y="480.785">
                    Yeti
                  </text>
                  <image
                    x="674"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="755"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="836"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                  <image
                    x="916"
                    y="325"
                    width="72"
                    height="68"
                    href={starIcon}
                  ></image>
                </g>
                <image
                  className="pntNo"
                  href={cardBase}
                  width="1186"
                  height="1969"
                  x="0"
                  y="0"
                ></image>
              </svg>

              <button
                className="btn fight-btn"
                //disabled={key ? false : true}
                onClick={() => combat(18)}
              >
                Fight!!!
              </button>
            </div>
          </Slider>
          <Row className="justify-content-center">
            <Col lg="8" className="bottomSlider">
              {newArray.length > 3 ? (
                <Slider {...settings1}>
                  {check && newArray.length == index
                    ? characterInfo.map((e, i) => {
                        return (
                          <>
                            <div className="mons-card-cnt" key={i}>
                              <svg
                                className="active-card"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="1272"
                                height="2079"
                                viewBox="0 0 1272 2079"
                              >
                                <defs>
                                  <filter
                                    id="filter-edtName"
                                    x="703"
                                    y="301"
                                    width="407"
                                    height="207"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="20.667"
                                      in="SourceAlpha"
                                    />
                                    <feComposite result="composite" />
                                    <feComposite result="composite-2" />
                                    <feComposite result="composite-3" />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fb89ff"
                                    />
                                    <feComposite
                                      result="composite-4"
                                      operator="in"
                                      in2="composite-3"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend-2"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-barFill-1-3"
                                    x="450"
                                    y="1473"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="450"
                                      y="1473"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNmNjAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZkMjAwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSI2MDUiIGhlaWdodD0iNDAiLz4KPC9zdmc+Cg=="
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-barFill-2-3"
                                    x="450"
                                    y="1559"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="450"
                                      y="1559"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiMwOGIwMDAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIwLjAwMyIgc3RvcC1jb2xvcj0iIzA4YjAwMCIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM1YWZmMDAiLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjYwNSIgaGVpZ2h0PSI0MCIvPgo8L3N2Zz4K"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                </defs>
                                <g>
                                  <g>
                                    <g>
                                      <image
                                        className="pntNo"
                                        x="407"
                                        y="1780"
                                        width="463"
                                        height="280"
                                        href={cardTriangle2}
                                      />
                                      <image
                                        className="pntNo"
                                        x="168"
                                        y="277"
                                        width="937"
                                        height="1580"
                                        href={cardBgV34}
                                      ></image>
                                    </g>
                                    <image
                                      className="pntNo"
                                      x="112"
                                      y="215"
                                      width="1060"
                                      height="398"
                                      href={cardNameBoardV3}
                                    ></image>
                                    <image
                                      className="pntNo"
                                      x="0"
                                      y="0"
                                      width="1272"
                                      height="2079"
                                      href={cardNameBoardV331}
                                    ></image>
                                    <text
                                      className="plName-3"
                                      transform="translate(275 440.708) scale(1.6 1.599)"
                                    >
                                      {e.charName}{" "}
                                    </text>
                                    <text
                                      className="ntName-3"
                                      x="773.912"
                                      y="530.785"
                                    >
                                      {" "}
                                      Sigma asteria{" "}
                                    </text>
                                    <text
                                      className="mntName-3"
                                      x="273.911"
                                      y="531.785"
                                    >
                                      {/*"Galacticat"*/}
                                    </text>
                                    <text
                                      className="edtName"
                                      x="903.607"
                                      y="396.987"
                                    >
                                      <tspan x="903.607">Founders</tspan>
                                      <tspan x="903.607" dy="48">
                                        Edition
                                      </tspan>
                                    </text>
                                  </g>
                                </g>

                                <image
                                    className="pntNo"
                                    id="cardMonster5"
                                    x="61"
                                    y="538"
                                    width="1210"
                                    height="1000"
                                    href={
                                      charImages(e)
                                    }
                                  ></image>

                                <g>
                                  <text
                                    className="barLbl-3"
                                    x="242.383"
                                    y="1513.743"
                                  >
                                    Power
                                  </text>
                                  <path
                                    className="barBg-1-3"
                                    d="M450,1473h605v40H450v-40Z"
                                  />
                                  <rect
                                    className="barFill-1-3"
                                    x="450"
                                    y="1473"
                                    width="393.25"
                                    height="40"
                                  />
                                  <text
                                    className="barVal-3"
                                    x="967.492"
                                    y="1480.687"
                                  >
                                    {e.power}
                                  </text>

                                  <text
                                    className="barLbl-3"
                                    x="236.383"
                                    y="1596.743"
                                  >
                                    Accuracy
                                  </text>
                                  <path
                                    className="barBg-2-3"
                                    d="M450,1559h605v40H450v-40Z"
                                  />
                                  <rect
                                    className="barFill-2-3"
                                    x="450"
                                    y="1559"
                                    width="393.25"
                                    height="40"
                                  />
                                  <text
                                    className="barVal-3"
                                    x="967.492"
                                    y="1567.687"
                                  >
                                    {e.baseAccuracy}
                                  </text>
                                </g>
                                <g>
                                  <path
                                    className="tmrBg-3"
                                    d="M1035.07,1767.99l-727.566-.3-28.5-58.99,28.938-58.7,727.563,0.3,28.5,59Z"
                                  />
                                  <text
                                    
                                    className="def-txt"
                                    x="391.767"
                                    y="1726.607"
                                  >
                                    {e.skillRarity == 0 ? "Common" : e.skillRarity == 1 ? "Uncommon" : "Rare"}
                                  </text>
                                  <image
                                    className="pntNo"
                                    x="229"
                                    y="1635"
                                    width="148"
                                    height="148"
                                    href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                                  ></image>
                                </g>
                                <image
                                  className="pntNo"
                                  x="457"
                                  y="69"
                                  width="363"
                                  height="286"
                                  href={cardFace3}
                                />
                                <image
                                  className="pntNo"
                                  x="452"
                                  y="1761"
                                  width="370"
                                  height="235"
                                  href={cardTriangle1}
                                />
                              </svg>
                              <svg
                                className="normal-card"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="1233"
                                height="2000"
                                viewBox="0 0 1233 2000"
                              >
                                <defs>
                                  <filter
                                    id="filter-barFill-1"
                                    x="427"
                                    y="1420"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="427"
                                      y="1420"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNmNjAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZkMjAwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSI2MDUiIGhlaWdodD0iNDAiLz4KPC9zdmc+Cg=="
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-barFill-2"
                                    x="427"
                                    y="1506"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="427"
                                      y="1506"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiMwOGIwMDAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIwLjAwMyIgc3RvcC1jb2xvcj0iIzA4YjAwMCIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM1YWZmMDAiLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjYwNSIgaGVpZ2h0PSI0MCIvPgo8L3N2Zz4K"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-star2"
                                    x="916"
                                    y="310"
                                    width="94"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset
                                      result="offset"
                                      dx="0.5"
                                      dy="0.866"
                                      in="SourceAlpha"
                                    />
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="1.732"
                                    />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fff"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="blur"
                                    />
                                    <feBlend
                                      result="blend"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-star22"
                                    x="820"
                                    y="310"
                                    width="94"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset
                                      result="offset"
                                      dx="0.5"
                                      dy="0.866"
                                      in="SourceAlpha"
                                    />
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="1.732"
                                    />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fff"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="blur"
                                    />
                                    <feBlend
                                      result="blend"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-star23"
                                    x="724"
                                    y="310"
                                    width="94"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset
                                      result="offset"
                                      dx="0.5"
                                      dy="0.866"
                                      in="SourceAlpha"
                                    />
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="1.732"
                                    />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fff"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="blur"
                                    />
                                    <feBlend
                                      result="blend"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                </defs>

                                <image
                                  className="pntNo"
                                  x="151"
                                  y="274"
                                  width="939"
                                  height="1551"
                                  href={cardBgv2}
                                ></image>

                                <image
                                  className="pntNo"
                                  x="140"
                                  y="216"
                                  width="963"
                                  height="291"
                                  href={cardNameBoard2}
                                ></image>
                                <text
                                  className="plName-2"
                                  transform="translate(260 388.708) scale(1.6 1.599)"
                                >
                                  {e.charName}{" "}
                                </text>
                                <text
                                  className="ntName-2"
                                  x="735.911"
                                  y="478.784"
                                >
                                  Nation: Atrapax{" "}
                                </text>
                                <text
                                  className="mntName-2"
                                  x="258.912"
                                  y="479.784"
                                >
                                  {/*"Hell cat"*/}
                                </text>

                                <path
                                  className="star-2"
                                  d="M962,314l13.842,25.14,28.148,5.416L984.4,365.511,987.954,394,962,381.807,936.045,394l3.558-28.487-19.6-20.953,28.153-5.416L962,314"
                                />
                                <path
                                  className="star-22"
                                  d="M866,314l13.842,25.14,28.152,5.416-19.6,20.953L891.954,394,866,381.807,840.045,394l3.558-28.487-19.6-20.953,28.153-5.416L866,314"
                                />
                                <path
                                  className="star-23"
                                  d="M770,314l13.842,25.14,28.152,5.416-19.6,20.953L795.954,394,770,381.807,744.045,394l3.558-28.487-19.6-20.953,28.153-5.416L770,314"
                                />

                                {e.level == 1 ? (
                                  <image
                                    className="pntNo"
                                    id="cardMonster5"
                                    x="61"
                                    y="538"
                                    width="1210"
                                    height="1000"
                                    href={
                                      charImages(e)
                                    }
                                  ></image>
                                ) : e.level == 2 ? (
                                  <image
                                    className="pntNo"
                                    id="cardMonster5"
                                    x="61"
                                    y="538"
                                    width="1210"
                                    height="1000"
                                    href={
                                      charImages(e)
                                    }
                                  ></image>
                                ) : e.level == 3 ? (
                                  <image
                                    className="pntNo"
                                    id="cardMonster5"
                                    x="61"
                                    y="538"
                                    width="1210"
                                    height="1000"
                                    href={
                                      charImages(e)
                                    }
                                  ></image>
                                ) : null}

                                <text
                                  className="barLbl"
                                  x="219.384"
                                  y="1460.742"
                                >
                                  Power
                                </text>
                                <path
                                  className="barBg-1"
                                  d="M427,1420h605v40H427v-40Z"
                                />
                                <rect
                                  className="barFill-1"
                                  x="427"
                                  y="1420"
                                  width="393.25"
                                  height="40"
                                />
                                <text
                                  className="barVal"
                                  x="944.492"
                                  y="1427.686"
                                >
                                  {e.power}
                                </text>

                                <text
                                  className="barLbl"
                                  x="213.384"
                                  y="1543.742"
                                >
                                  Accuracy
                                </text>
                                <path
                                  className="barBg-2"
                                  d="M427,1506h605v40H427v-40Z"
                                />
                                <rect
                                  className="barFill-2"
                                  x="427"
                                  y="1506"
                                  width="393.25"
                                  height="40"
                                />
                                <text
                                  className="barVal"
                                  x="944.492"
                                  y="1514.686"
                                >
                                  {e.baseAccuracy}
                                </text>

                                <path
                                  className="tmrBg"
                                  d="M1012.07,1714.99l-727.566-.3-28.5-58.99,28.938-58.7,727.563,0.3,28.5,59Z"
                                />
                                <text
                                  className="tmrTx"
                                  x="368.767"
                                  y="1673.608"
                                >
                                  {e.skillRarity == 0 ? "Common" : e.skillRarity == 1 ? "Uncommon" : "Rare"}
                                </text>

                                <image
                                  className="pntNo"
                                  x="206"
                                  y="1582"
                                  width="148"
                                  height="148"
                                  href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                                ></image>
                                <image
                                  className="pntNo"
                                  x="0"
                                  y="0"
                                  width="1233"
                                  height="2000"
                                  href={cardBasev2}
                                ></image>
                                <image
                                  className="pntNo"
                                  x="425"
                                  y="52"
                                  width="398"
                                  height="255"
                                  href={cardFace}
                                ></image>
                              </svg>
                            </div>
                          </>
                        );
                      })
                    : null}
                </Slider>
              ) : (
                <Slider {...settings2} className="threeCardSlider">
                  {check && newArray.length == index
                    ? characterInfo.map((e, i) => {
                        return (
                          <>
                            <div className="mons-card-cnt" key={i}>
                              <svg
                                className="active-card"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="1272"
                                height="2079"
                                viewBox="0 0 1272 2079"
                              >
                                <defs>
                                  <filter
                                    id="filter-edtName"
                                    x="703"
                                    y="301"
                                    width="407"
                                    height="207"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="20.667"
                                      in="SourceAlpha"
                                    />
                                    <feComposite result="composite" />
                                    <feComposite result="composite-2" />
                                    <feComposite result="composite-3" />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fb89ff"
                                    />
                                    <feComposite
                                      result="composite-4"
                                      operator="in"
                                      in2="composite-3"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend-2"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-barFill-1-3"
                                    x="450"
                                    y="1473"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="450"
                                      y="1473"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNmNjAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZkMjAwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSI2MDUiIGhlaWdodD0iNDAiLz4KPC9zdmc+Cg=="
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-barFill-2-3"
                                    x="450"
                                    y="1559"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="450"
                                      y="1559"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiMwOGIwMDAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIwLjAwMyIgc3RvcC1jb2xvcj0iIzA4YjAwMCIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM1YWZmMDAiLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjYwNSIgaGVpZ2h0PSI0MCIvPgo8L3N2Zz4K"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                </defs>
                                <g>
                                  <g>
                                    <g>
                                      <image
                                        className="pntNo"
                                        x="407"
                                        y="1780"
                                        width="463"
                                        height="280"
                                        href={cardTriangle2}
                                      />
                                      <image
                                        className="pntNo"
                                        x="168"
                                        y="277"
                                        width="937"
                                        height="1580"
                                        href={cardBgV34}
                                      ></image>
                                    </g>
                                    <image
                                      className="pntNo"
                                      x="112"
                                      y="215"
                                      width="1060"
                                      height="398"
                                      href={cardNameBoardV3}
                                    ></image>
                                    <image
                                      className="pntNo"
                                      x="0"
                                      y="0"
                                      width="1272"
                                      height="2079"
                                      href={cardNameBoardV331}
                                    ></image>
                                    <text
                                      className="plName-3"
                                      transform="translate(275 440.708) scale(1.6 1.599)"
                                    >
                                      {e.charName}{" "}
                                    </text>
                                    <text
                                      className="ntName-3"
                                      x="773.912"
                                      y="530.785"
                                    >
                                      {" "}
                                      Sigma asteria{" "}
                                    </text>
                                    <text
                                      className="mntName-3"
                                      x="273.911"
                                      y="531.785"
                                    >
                                      {/*"Galacticat"*/}
                                    </text>
                                    <text
                                      className="edtName"
                                      x="903.607"
                                      y="396.987"
                                    >
                                      <tspan x="903.607">Founders</tspan>
                                      <tspan x="903.607" dy="48">
                                        Edition
                                      </tspan>
                                    </text>
                                  </g>
                                </g>

                                {e.level == 1 ? (
                                  <image
                                    className="pntNo"
                                    id="cardMonster6"
                                    x="61"
                                    y="538"
                                    width="1210"
                                    height="1000"
                                    href={
                                      charImages(e)
                                    }
                                  ></image>
                                ) : e.level == 2 ? (
                                  <image
                                    className="pntNo"
                                    id="cardMonster6"
                                    x="61"
                                    y="538"
                                    width="1210"
                                    height="1000"
                                    href={
                                      charImages(e)
                                    }
                                  ></image>
                                ) : e.level == 3 ? (
                                  <image
                                    className="pntNo"
                                    id="cardMonster6"
                                    x="61"
                                    y="538"
                                    width="1210"
                                    height="1000"
                                    href={
                                      charImages(e)
                                    }
                                  ></image>
                                ) : null}

                                <g>
                                  <text
                                    className="barLbl-3"
                                    x="242.383"
                                    y="1513.743"
                                  >
                                    Power
                                  </text>
                                  <path
                                    className="barBg-1-3"
                                    d="M450,1473h605v40H450v-40Z"
                                  />
                                  <rect
                                    className="barFill-1-3"
                                    x="450"
                                    y="1473"
                                    width="393.25"
                                    height="40"
                                  />
                                  <text
                                    className="barVal-3"
                                    x="967.492"
                                    y="1480.687"
                                  >
                                    {e.power}
                                  </text>

                                  <text
                                    className="barLbl-3"
                                    x="236.383"
                                    y="1596.743"
                                  >
                                    Accuracy
                                  </text>
                                  <path
                                    className="barBg-2-3"
                                    d="M450,1559h605v40H450v-40Z"
                                  />
                                  <rect
                                    className="barFill-2-3"
                                    x="450"
                                    y="1559"
                                    width="393.25"
                                    height="40"
                                  />
                                  <text
                                    className="barVal-3"
                                    x="967.492"
                                    y="1567.687"
                                  >
                                    {e.baseAccuracy}
                                  </text>
                                </g>
                                <g>
                                  <path
                                    className="tmrBg-3"
                                    d="M1035.07,1767.99l-727.566-.3-28.5-58.99,28.938-58.7,727.563,0.3,28.5,59Z"
                                  />
                                  <text
                                    className="def-txt"
                                    x="391.767"
                                    y="1726.607"
                                  >
                                    {e.skillRarity == 0 ? "Common" : e.skillRarity == 1 ? "Uncommon" : "Rare"}
                                  </text>
                                  <image
                                    className="pntNo"
                                    x="229"
                                    y="1635"
                                    width="148"
                                    height="148"
                                    href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                                  ></image>
                                </g>
                                <image
                                  className="pntNo"
                                  x="457"
                                  y="69"
                                  width="363"
                                  height="286"
                                  href={cardFace3}
                                />
                                <image
                                  className="pntNo"
                                  x="452"
                                  y="1761"
                                  width="370"
                                  height="235"
                                  href={cardTriangle1}
                                />
                              </svg>
                              <svg
                                className="normal-card"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="1233"
                                height="2000"
                                viewBox="0 0 1233 2000"
                              >
                                <defs>
                                  <filter
                                    id="filter-barFill-1"
                                    x="427"
                                    y="1420"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="427"
                                      y="1420"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNmNjAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZkMjAwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSI2MDUiIGhlaWdodD0iNDAiLz4KPC9zdmc+Cg=="
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-barFill-2"
                                    x="427"
                                    y="1506"
                                    width="605"
                                    height="40"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feImage
                                      preserveAspectRatio="none"
                                      x="427"
                                      y="1506"
                                      width="605"
                                      height="40"
                                      result="image"
                                      xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjA1IiBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgNjA1IDQwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeDE9IjMwMi41IiB5MT0iNDAiIHgyPSIzMDIuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiMwOGIwMDAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIwLjAwMyIgc3RvcC1jb2xvcj0iIzA4YjAwMCIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM1YWZmMDAiLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjYwNSIgaGVpZ2h0PSI0MCIvPgo8L3N2Zz4K"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="SourceGraphic"
                                    />
                                    <feBlend
                                      result="blend"
                                      in2="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-star2"
                                    x="916"
                                    y="310"
                                    width="94"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset
                                      result="offset"
                                      dx="0.5"
                                      dy="0.866"
                                      in="SourceAlpha"
                                    />
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="1.732"
                                    />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fff"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="blur"
                                    />
                                    <feBlend
                                      result="blend"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-star22"
                                    x="820"
                                    y="310"
                                    width="94"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset
                                      result="offset"
                                      dx="0.5"
                                      dy="0.866"
                                      in="SourceAlpha"
                                    />
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="1.732"
                                    />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fff"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="blur"
                                    />
                                    <feBlend
                                      result="blend"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                  <filter
                                    id="filter-star23"
                                    x="724"
                                    y="310"
                                    width="94"
                                    height="90"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feOffset
                                      result="offset"
                                      dx="0.5"
                                      dy="0.866"
                                      in="SourceAlpha"
                                    />
                                    <feGaussianBlur
                                      result="blur"
                                      stdDeviation="1.732"
                                    />
                                    <feFlood
                                      result="flood"
                                      flood-color="#fff"
                                    />
                                    <feComposite
                                      result="composite"
                                      operator="in"
                                      in2="blur"
                                    />
                                    <feBlend
                                      result="blend"
                                      in="SourceGraphic"
                                    />
                                  </filter>
                                </defs>

                                <image
                                  className="pntNo"
                                  x="151"
                                  y="274"
                                  width="939"
                                  height="1551"
                                  href={cardBgv2}
                                ></image>

                                <image
                                  className="pntNo"
                                  x="140"
                                  y="216"
                                  width="963"
                                  height="291"
                                  href={cardNameBoard2}
                                ></image>
                                <text
                                  className="plName-2"
                                  transform="translate(260 388.708) scale(1.6 1.599)"
                                >
                                  {e.charName}
                                </text>
                                <text
                                  className="ntName-2"
                                  x="735.911"
                                  y="478.784"
                                >
                                  Nation: Atrapax{" "}
                                </text>
                                <text
                                  className="mntName-2"
                                  x="258.912"
                                  y="479.784"
                                >
                                  {/*"Hell cat"*/}
                                </text>

                                <path
                                  className="star-2"
                                  d="M962,314l13.842,25.14,28.148,5.416L984.4,365.511,987.954,394,962,381.807,936.045,394l3.558-28.487-19.6-20.953,28.153-5.416L962,314"
                                />
                                <path
                                  className="star-22"
                                  d="M866,314l13.842,25.14,28.152,5.416-19.6,20.953L891.954,394,866,381.807,840.045,394l3.558-28.487-19.6-20.953,28.153-5.416L866,314"
                                />
                                <path
                                  className="star-23"
                                  d="M770,314l13.842,25.14,28.152,5.416-19.6,20.953L795.954,394,770,381.807,744.045,394l3.558-28.487-19.6-20.953,28.153-5.416L770,314"
                                />

                                <text
                                  className="barLbl"
                                  x="219.384"
                                  y="1460.742"
                                >
                                  Power
                                </text>
                                <path
                                  className="barBg-1"
                                  d="M427,1420h605v40H427v-40Z"
                                />
                                <rect
                                  className="barFill-1"
                                  x="427"
                                  y="1420"
                                  width="393.25"
                                  height="40"
                                />
                                <text
                                  className="barVal"
                                  x="944.492"
                                  y="1427.686"
                                >
                                  {e.power}
                                </text>

                                <text
                                  className="barLbl"
                                  x="213.384"
                                  y="1543.742"
                                >
                                  Accuracy
                                </text>
                                <path
                                  className="barBg-2"
                                  d="M427,1506h605v40H427v-40Z"
                                />
                                <rect
                                  className="barFill-2"
                                  x="427"
                                  y="1506"
                                  width="393.25"
                                  height="40"
                                />
                                <text
                                  className="barVal"
                                  x="944.492"
                                  y="1514.686"
                                >
                                  {e.baseAccuracy}
                                </text>

                                <path
                                  className="tmrBg"
                                  d="M1012.07,1714.99l-727.566-.3-28.5-58.99,28.938-58.7,727.563,0.3,28.5,59Z"
                                />
                                <text
                                  className="tmrTx"
                                  x="368.767"
                                  y="1673.608"
                                >
                                  {e.skillRarity == 0 ? "Common" : e.skillRarity == 1 ? "Uncommon" : "Rare"}
                                </text>

                                <image
                                  className="pntNo"
                                  x="206"
                                  y="1582"
                                  width="148"
                                  height="148"
                                  href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                                ></image>
                                <image
                                  className="pntNo"
                                  x="0"
                                  y="0"
                                  width="1233"
                                  height="2000"
                                  href={cardBasev2}
                                ></image>
                                <image
                                  className="pntNo"
                                  x="425"
                                  y="52"
                                  width="398"
                                  height="255"
                                  href={cardFace}
                                ></image>
                              </svg>
                            </div>
                          </>
                        );
                      })
                    : null}
                </Slider>
              )}
            </Col>
          </Row>
        </Container>
        <div className="homeBottomcnt d-flex flex-column bottomLeft-cnt">
          <p>
            <span className="red-txt">Last Attack: </span>
            12/12/2021
          </p>
          <p>
            <span className="grn-txt">Stack: </span>
            {characterData[currentCell]?.rosterInfo?._onRoaster && characterData[currentCell]?.rosterInfo?._cooldown != 0 ? characterData[currentCell]?.rosterInfo?._attackStacks : 0}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
