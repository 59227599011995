import React, { Fragment, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./css/roaster.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ABI from "../../ABI/DefSpaceCharacters.json";
import BATTLE from "../../ABI/BattleContract.json";
import Loader from "react-loader-spinner";

// Components
import cardMonster1 from "../../assets/images/card-monster-1.png";
import cardMonster2 from "../../assets/images/card-monster-3.png";
import cardMonster3 from "../../assets/images/card-monster-3.png";
import cardMonster4 from "../../assets/images/card-monster-4.png";
import cardMonster5 from "../../assets/images/card-monster-5.png";
import cardMonster6 from "../../assets/images/card-monster.png";
import cardMonster from "../../assets/images/card-monster.png";
import cardBase from "../../assets/images/card-base.png";
import sliderPrevious from "../../assets/images/slider-left-arrow.png";
import sliderNext from "../../assets/images/slider-right-arrow.png";

import chooseCharacterTitle from "../../assets/images/chooseCharacterTitle.png";
import plusIcon from "../../assets/images/plusIcon.png";
import buttonRed from "../../assets/images/button-red.png";
import buttonGreen from "../../assets/images/button-green.png";
import dragonCat from "../../assets/images/dragon-cat.png";
import dragon from "../../assets/images/dragon.png";
import starActive from "../../assets/images/star-active.png";
import starInactive from "../../assets/images/star-inactive.png";
import bronzeMini from "../../assets/images/bronze_mini.png";

import common from "../../assets/images/common_mini.png";
import uncommon from "../../assets/images/uncommon_mini.png";
import rare from "../../assets/images/rare_mini.png";
import { Battle_contract, Character_contract, charImages } from "../../common/Constant";
//import { create } from "underscore";

const Web3 = require("web3");
const web3 = new Web3(window.ethereum);

const Choosecharacter = (props) => {
  const [allCharacters, setAllCharacters] = useState([]);
  const [roasterCharacters, setRoasterCharacters] = useState([]);
  const [inRoaster, setInRoaster] = useState();
  const [dummyCharacters, setDummyCharacters] = useState([]);
  const [searchData, setSearchData] = useState();
  const [trail, setTrail] = useState(1);

  const [makeItTrue, setMakeItTrue] = useState(false);
  const [searchTrue, setSearchTrue] = useState(false);
  const [roasterTrue, setRoasterTrue] = useState(false);

  
  var create_contract = new web3.eth.Contract(BATTLE, Battle_contract);
  var create_defpace_contract = new web3.eth.Contract(ABI, Character_contract);
  var add = JSON.parse(localStorage.getItem("metaAddress"));

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 5,
    initialSlide: 0,
    nextArrow: <img src={sliderNext} />,
    prevArrow: <img src={sliderPrevious} />,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "0px",
          className: "center",
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          speed: 500,
          rows: 1,
          slidesPerRow: 1,
          centerPadding: "0px",
          className: "center",
          centerMode: true,
          unslick: true,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    var address = localStorage.getItem("metaAddress");
    if (address) {
      roasterinfo();
      cardInfo();
    } else {
      alert("Connect to wallet");
    }
    var sets = setTimeout(trueAfterSecond, 3000);
    return () => {};
  }, [trail]);

  const trueAfterSecond = () => {
    setMakeItTrue(true);
    setRoasterTrue(true);
  };

  const onChanging = (e) => {
    let a = e.target.value;
    if (a === "") {
      setSearchTrue(false);
      setMakeItTrue(true);
    }
    //console.log(allCharacters)
    const data = allCharacters.filter((word) => word.key == a);
    if (data.length > 0) {
      setSearchTrue(true);
      setMakeItTrue(false);
      setSearchData(data);
    }
    if (data.length == 0) {
      setSearchTrue(false);
      setMakeItTrue(true);
    }
  };
  const roasterinfo = () => {
    //  setRoasterTrue(false)
    var address = JSON.parse(localStorage.getItem("metaAddress"));

    create_contract.methods.userInfo(address).call(async (err, resp) => {
      setInRoaster(resp._charIndexRoaster);
    });

    var roast = [];
    var empty = [];
    for (let i = 1; i <= 10; i++) {
      create_contract.methods
        .getRoasterInfo(address, i)
        .call(async (err, resp) => {
          create_defpace_contract.methods
            .charInfo(resp._keyID)
            .call((err, char_info) => {
              create_contract.methods
                .getKeyIndex(address, resp._keyID)
                .call((err, char_index) => {
                  char_info.id = resp._keyID;
                  char_info.index = char_index;
                  //  console.log(resp, out)
                  if (char_info.id > 0) {
                    console.log(char_info);
                    roast.push(char_info);
                  } else {
                    empty.push(char_info);
                  }
                  //resp._keyID
                });
            });
        });
    }
    setRoasterCharacters(roast);
    setDummyCharacters(empty);
  };

  const cardInfo = async () => {
    var address = add;
    await create_defpace_contract.methods
      .balanceOf(address)
      .call((err, current_id) => {
        let total = [];
        for (let i = 0; i < current_id; i++) {
           create_defpace_contract.methods
            .tokenOfOwnerByIndex(address, i)
            .call((errId, id) => {
              create_defpace_contract.methods
                .charInfo(id)
                .call((errVal, val) => {
                   create_contract.methods
                    .getKeyIndex(address, i)
                    .call((errRes, res) => {
                      val.id = id;
                      var index_value =  val;
                      index_value.key =  i;
                      total.push(index_value);
                      console.log(index_value);
                    });
                });
            })
            .catch((err) => {});
        }
        setAllCharacters(total);
      });
  };

  const addToRoaster = async (keyid) => {
    console.log(keyid);
    var address = add;
    let Web3 = require("web3");
    let value = 0;
    let BNB = Web3.utils.toWei(value.toString(), "ether");

    var approved = false; 
    await create_defpace_contract.methods.isApprovedForAll(address, Battle_contract).call(async(errApp, resApp) => {
      if(!errApp) {
        approved = resApp;
      }
    });
    if(!approved) {
      await create_defpace_contract.methods.setApprovalForAll(Battle_contract, true).send({from: address}, async(err, res) => {
        if(res) {
          approved = true;
        }
      });
    }
    console.log(approved);
    if(!approved) return;
    create_contract.methods
      .addCharToRoaster(keyid)
      .send(
        {
          from: address,
          value: BNB,
        },
        function (error, transactionHash) {
          if (transactionHash) console.log(transactionHash);
        }
      )
      .on("error", function (error) {
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log(transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt.contractAddress); // contains the new contract address
      })
      .on("confirmation", function (confirmationNumber, receipt) {})
      .then(function (newContractInstance) {
        console.log(newContractInstance);
        setMakeItTrue(false);
        setRoasterTrue(false);
        const fetchData = () => {
          setTrail(2);
        };
        setTimeout(fetchData, 1000);
        // instance with the new contract address
      });
  };
  const removeCharacter = (index) => {
    var address = add;
    let Web3 = require("web3");

    let value = 0;
    let BNB = Web3.utils.toWei(value.toString(), "ether");

    create_contract.methods
      .swapCharacter(0, index)
      .send(
        {
          from: address,
          value: BNB,
        },
        function (error, transactionHash) {
          if (transactionHash) console.log(transactionHash);
        }
      )
      .on("error", function (error) {
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log(transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt.contractAddress); // contains the new contract address
      })
      .on("confirmation", function (confirmationNumber, receipt) {})
      .then(function (newContractInstance) {
        console.log(newContractInstance);
        setMakeItTrue(false);
        setRoasterTrue(false);
        const fetchDatas = () => {
          setTrail(3);
        };
        setTimeout(fetchDatas, 1000);
        // instance with the new contract address
      });
  };

  return (
    <Fragment>
      <div className="chooseCharacCnt">
        <Row className="topRow">
          <Col xl="4">
            <div className="searchCnt d-flex align-items-center">
              <span>Search Id : </span>
              <input type="text" onChange={onChanging} />
            </div>
          </Col>
          <Col xl="8">
            <img
              src={chooseCharacterTitle}
              className="img-fluid chooseCharacterImg"
            />
          </Col>
        </Row>
        <Row>
          <Col xl="4">
            <div className="leftCnt">
              <div className="titleCnt d-flex">
                <h3>Battle Deck</h3>
                <div className="ml-auto">
                  <p>
                    <span className="blue-txt">Roster:</span>
                    {inRoaster}/10
                  </p>
                </div>
              </div>
              <div className="row topLeftRow">
                {roasterTrue && roasterCharacters
                  ? roasterCharacters.map((e, i) => {
                      return (
                        <div className="col col-xl-6">
                          <div className="battle-card">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="300"
                              height="513"
                              viewBox="0 0 300 513"
                            >
                              <path
                                className="pntNo battle-card-bg"
                                d="M12.565,1.9H287.833a10.552,10.552,0,0,1,10.541,10.562V471.538A10.552,10.552,0,0,1,287.833,482.1H12.565A10.552,10.552,0,0,1,2.024,471.538V12.462A10.551,10.551,0,0,1,12.565,1.9Z"
                              />
                              <g
                                className="btnG"
                                onClick={() => removeCharacter(e.index)}
                              >
                                <image
                                  x="55"
                                  y="457"
                                  width="191"
                                  height="56"
                                  href={buttonRed}
                                ></image>
                                <text
                                  className="battle-btn-tx"
                                  transform="translate(148.102 490.361) scale(0.486 0.483)"
                                >
                                  <tspan x="0">Remove</tspan>
                                </text>
                              </g>
                              <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={
                                    /*e.charName == 1
                                      ? cardMonster1
                                      : e.charName == 2
                                      ? cardMonster3
                                      : e.charName == 3
                                      ? cardMonster
                                      : e.charName == 4
                                      ? cardMonster4
                                      : e.charName == 5
                                      ? cardMonster5
                                      : cardBase*/
                                    charImages(e)
                                  }
                                ></image>
                              <image
                                x="248"
                                y="11"
                                width="41"
                                height="42"
                                href={dragon}
                              ></image>

                              <image
                                x="51"
                                y="5"
                                width="47"
                                height="45"
                                href={
                                  e.level >= 1
                                    ? starActive
                                    : starInactive
                                }
                                className="star-active"
                              ></image>
                              <image
                                x="85"
                                y="5"
                                width="47"
                                height="45"
                                href={
                                  e.level >= 2
                                    ? starActive
                                    : starInactive
                                }
                                className="inactStar"
                              ></image>
                              <image
                                x="117"
                                y="5"
                                width="47"
                                height="45"
                                href={
                                  e.level >= 3 ? starActive : starInactive
                                }
                                className="inactStar"
                              ></image>
                              <image
                                x="152"
                                y="5"
                                width="47"
                                height="45"
                                href={e.level == 4 ? starActive : starInactive}
                                className="inactStar"
                              ></image>

                              <text
                                className="battle-id"
                                transform="translate(284.123 85.706) scale(0.944 0.943)"
                                text-anchor="end"
                              >
                                #{e.id}
                              </text>
                              <text
                                className="battle-nm"
                                transform="translate(18.55 85.985) scale(0.785 0.784)"
                              >
                                {/*e.charName == 1
                                  ? "Freathes"
                                  : e.charName == 2
                                  ? "Jikiru"
                                  : e.charName == 3
                                  ? "OraLillystorm"
                                  : e.charName == 4
                                  ? "Asheal"
                                  : e.charName == 5
                                  ? "Rizgath"
                                : "Ratcher"*/}
                                {e.charName}
                              </text>

                              <text
                                className="battle-pwr-lbl"
                                transform="translate(21.611 404.051) scale(0.331 0.33)"
                              >
                                Power:
                              </text>
                              <text
                                className="battle-pwr-tx"
                                transform="translate(87.291 404.374) scale(0.98)"
                              >
                                {e.power}
                              </text>

                              <text
                                className="battle-acr-lbl"
                                transform="translate(168.806 403.081) scale(0.414 0.413)"
                              >
                                Acurracy:
                              </text>
                              <text
                                className="battle-acr-tx"
                                transform="translate(258.791 404.374) scale(0.98)"
                              >
                                {e.baseAccuracy}
                              </text>

                              <image
                                x="12"
                                y="8"
                                width="39"
                                height="43"
                                href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                              ></image>
                            </svg>
                          </div>
                        </div>
                      );
                    })
                  : null}

                {roasterTrue && dummyCharacters ? (
                  dummyCharacters.map((e, i) => {
                    return (
                      <div className="col col-xl-6 d-flex">
                        <div className="leftInnerCnt d-flex justify-content-center align-items-center">
                          <img src={plusIcon} className="img-fluid" />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={3000} //3 secs
                  />
                )}
              </div>
            </div>
          </Col>
          <Col xl="8">
            <div className="rightCnt">
              <div className="titleCnt d-flex">
                <h3>Card Collection</h3>
                <div className="ml-auto">
                  <p>
                    <span className="blue-txt">Total Characters:</span>{" "}
                    {allCharacters.length}
                  </p>
                </div>
              </div>

              <div className="w-100 d-none d-xl-block">
                <Slider {...settings}>
                  {searchTrue && searchData ? (
                    searchData ? (
                      searchData.map((e, i) => {
                        return (
                          <div className="battle-card">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="300"
                              height="513"
                              viewBox="0 0 300 513"
                            >
                              <path
                                className="pntNo battle-card-bg"
                                d="M12.565,1.9H287.833a10.552,10.552,0,0,1,10.541,10.562V471.538A10.552,10.552,0,0,1,287.833,482.1H12.565A10.552,10.552,0,0,1,2.024,471.538V12.462A10.551,10.551,0,0,1,12.565,1.9Z"
                              />
                              <g
                                class="btnG"
                                onClick={() => addToRoaster(e.id)}
                              >
                                <image
                                  x="55"
                                  y="457"
                                  width="191"
                                  height="56"
                                  href={buttonGreen}
                                ></image>
                                <text
                                  class="battle-btn-tx"
                                  transform="translate(148.102 490.361) scale(0.486 0.483)"
                                >
                                  <tspan x="0">Deploy</tspan>
                                </text>
                              </g>

                              {e.level == 1 ? (
                                <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={
                                    /*e.charName == 1
                                      ? cardMonster1
                                      : e.charName == 2
                                      ? cardMonster3
                                      : e.charName == 3
                                      ? cardMonster
                                      : e.charName == 4
                                      ? cardMonster4
                                      : e.charName == 5
                                      ? cardMonster5
                                      : cardBase*/
                                    charImages(e)
                                  }
                                ></image>
                              ) : e.level == 2 ? (
                                <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={
                                    /*e.charName == 1
                                      ? cardMonster1
                                      : e.charName == 2
                                      ? cardMonster3
                                      : e.charName == 3
                                      ? cardMonster
                                      : e.charName == 4
                                      ? cardMonster4
                                      : e.charName == 5
                                      ? cardMonster5
                                      : cardBase*/
                                    charImages(e)
                                  }
                                ></image>
                              ) : e.level == 3 ? (
                                <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={
                                    /*e.charName == 1
                                      ? cardMonster1
                                      : e.charName == 2
                                      ? cardMonster3
                                      : e.charName == 3
                                      ? cardMonster
                                      : e.charName == 4
                                      ? cardMonster4
                                      : e.charName == 5
                                      ? cardMonster5
                                      : cardBase*/
                                    charImages(e)
                                  }
                                ></image>
                              ) : null}

                              <image
                                x="248"
                                y="11"
                                width="41"
                                height="42"
                                href={dragon}
                              ></image>

                              <image
                                x="51"
                                y="5"
                                width="47"
                                height="45"
                                href={starActive}
                                className="star-active"
                              ></image>
                              <image
                                x="95"
                                y="14"
                                width="28"
                                height="26"
                                href={starInactive}
                                className="inactStar"
                              ></image>
                              <image
                                x="127"
                                y="14"
                                width="28"
                                height="26"
                                href={starInactive}
                                className="inactStar"
                              ></image>
                              <image
                                x="162"
                                y="14"
                                width="28"
                                height="26"
                                href={starInactive}
                                className="inactStar"
                              ></image>

                              <text
                                className="battle-id"
                                transform="translate(284.123 85.706) scale(0.944 0.943)"
                                text-anchor="end"
                              >
                                #{e.id}
                              </text>
                              <text
                                className="battle-nm"
                                transform="translate(18.55 85.985) scale(0.785 0.784)"
                              >
                                {/*e.charName == 1
                                  ? "Freathes"
                                  : e.charName == 2
                                  ? "Jikiru"
                                  : e.charName == 3
                                  ? "OraLillystorm"
                                  : e.charName == 4
                                  ? "Asheal"
                                  : e.charName == 5
                                  ? "Rizgath"
                                : "Ratcher"*/
                                e.charName}
                              </text>

                              <text
                                className="battle-pwr-lbl"
                                transform="translate(21.611 404.051) scale(0.331 0.33)"
                              >
                                Power:
                              </text>
                              <text
                                className="battle-pwr-tx"
                                transform="translate(87.291 404.374) scale(0.98)"
                              >
                                {e.power}
                              </text>

                              <text
                                className="battle-acr-lbl"
                                transform="translate(168.806 403.081) scale(0.414 0.413)"
                              >
                                Acurracy:
                              </text>
                              <text
                                className="battle-acr-tx"
                                transform="translate(258.791 404.374) scale(0.98)"
                              >
                                {e.baseAccuracy}
                              </text>

                              <image
                                x="12"
                                y="8"
                                width="39"
                                height="43"
                                href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                              ></image>
                            </svg>
                          </div>
                        );
                      })
                    ) : null
                  ) : makeItTrue && allCharacters ? (
                    allCharacters.map((e, i) => {
                      return (
                        <div className="battle-card">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="300"
                            height="513"
                            viewBox="0 0 300 513"
                          >
                            <path
                              className="pntNo battle-card-bg"
                              d="M12.565,1.9H287.833a10.552,10.552,0,0,1,10.541,10.562V471.538A10.552,10.552,0,0,1,287.833,482.1H12.565A10.552,10.552,0,0,1,2.024,471.538V12.462A10.551,10.551,0,0,1,12.565,1.9Z"
                            />
                            <g class="btnG" onClick={() => addToRoaster(e.id)}>
                              <image
                                x="55"
                                y="457"
                                width="191"
                                height="56"
                                href={buttonGreen}
                              ></image>
                              <text
                                class="battle-btn-tx"
                                transform="translate(148.102 490.361) scale(0.486 0.483)"
                              >
                                <tspan x="0">Deploy</tspan>
                              </text>
                            </g>

                            {e.level == 1 ? (
                              <image
                                x="30"
                                y="118"
                                width="232"
                                height="218"
                                href={
                                  /*e.charName == 1
                                    ? cardMonster1
                                    : e.charName == 2
                                    ? cardMonster3
                                    : e.charName == 3
                                    ? cardMonster
                                    : e.charName == 4
                                    ? cardMonster4
                                    : e.charName == 5
                                    ? cardMonster5
                                    : cardBase*/
                                  charImages(e)
                                }
                              ></image>
                            ) : e.level == 2 ? (
                              <image
                                x="30"
                                y="118"
                                width="232"
                                height="218"
                                href={
                                  /*e.charName == 1
                                    ? cardMonster1
                                    : e.charName == 2
                                    ? cardMonster3
                                    : e.charName == 3
                                    ? cardMonster
                                    : e.charName == 4
                                    ? cardMonster4
                                    : e.charName == 5
                                    ? cardMonster5
                                    : cardBase*/
                                  charImages(e)
                                }
                              ></image>
                            ) : e.level == 3 ? (
                              <image
                                x="30"
                                y="118"
                                width="232"
                                height="218"
                                href={
                                  /*e.charName == 1
                                    ? cardMonster1
                                    : e.charName == 2
                                    ? cardMonster3
                                    : e.charName == 3
                                    ? cardMonster
                                    : e.charName == 4
                                    ? cardMonster4
                                    : e.charName == 5
                                    ? cardMonster5
                                    : cardBase*/
                                  charImages(e)
                                }
                              ></image>
                            ) : null}
                            <image
                              x="248"
                              y="11"
                              width="41"
                              height="42"
                              href={dragon}
                            ></image>

                            <image
                              x="61"
                              y="5"
                              width="47"
                              height="45"
                              href={starActive}
                              className="star-active"
                            ></image>
                            <image
                              x="95"
                              y="5"
                              width="47"
                              height="45"
                              href={e.level >= 2 ? starActive : starInactive}
                              className={e.level >= 2 ? "star-active" : "inactStar"}
                            ></image>
                            <image
                              x="127"
                              y="5"
                              width="47"
                              height="45"
                              href={e.level >= 3 ? starActive : starInactive}
                              className={e.level >= 3 ? "star-active" : "inactStar"}
                            ></image>
                            <image
                              x="162"
                              y="5"
                              width="47"
                              height="45"
                              href={e.level >= 4 ? starActive : starInactive}
                              className={e.level >= 4 ? "star-active" : "inactStar"}
                            ></image>

                            <text
                              className="battle-id"
                              transform="translate(284.123 85.706) scale(0.944 0.943)"
                              text-anchor="end"
                            >
                              #{e.id}
                            </text>
                            <text
                              className="battle-nm"
                              transform="translate(18.55 85.985) scale(0.785 0.784)"
                            >
                              {/*e.charName == 1
                                ? "Freathes"
                                : e.charName == 2
                                ? "Jikiru"
                                : e.charName == 3
                                ? "OraLillystorm"
                                : e.charName == 4
                                ? "Asheal"
                                : e.charName == 5
                                ? "Rizgath"
                              : "Ratcher"*/
                              e.charName}
                            </text>

                            <text
                              className="battle-pwr-lbl"
                              transform="translate(21.611 404.051) scale(0.331 0.33)"
                            >
                              Power:
                            </text>

                            <text
                              className="battle-pwr-tx"
                              transform="translate(87.291 404.374) scale(0.98)"
                            >
                              {e.power}
                            </text>

                            <text
                              className="battle-acr-lbl"
                              transform="translate(168.806 403.081) scale(0.414 0.413)"
                            >
                              Acurracy:
                            </text>
                            <text
                              className="battle-acr-tx"
                              transform="translate(258.791 404.374) scale(0.98)"
                            >
                              {e.baseAccuracy}
                            </text>

                            <image
                              x="12"
                              y="8"
                              width="39"
                              height="43"
                              href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                            ></image>
                          </svg>
                        </div>
                      );
                    })
                  ) : (
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                      timeout={3000} //3 secs
                    />
                  )}
                </Slider>
              </div>

              <Row className="d-xl-none">
                <Col md="4">
                  {searchTrue && searchData ? (
                    searchData ? (
                      searchData.map((e, i) => {
                        return (
                          <div>
                            <div className="battle-card">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="300"
                                height="513"
                                viewBox="0 0 300 513"
                              >
                                <path
                                  className="pntNo battle-card-bg"
                                  d="M12.565,1.9H287.833a10.552,10.552,0,0,1,10.541,10.562V471.538A10.552,10.552,0,0,1,287.833,482.1H12.565A10.552,10.552,0,0,1,2.024,471.538V12.462A10.551,10.551,0,0,1,12.565,1.9Z"
                                />
                                <g
                                  class="btnG"
                                  onClick={() => addToRoaster(e.id)}
                                >
                                  <image
                                    x="55"
                                    y="457"
                                    width="191"
                                    height="56"
                                    href={buttonGreen}
                                  ></image>
                                  <text
                                    class="battle-btn-tx"
                                    transform="translate(148.102 490.361) scale(0.486 0.483)"
                                  >
                                    <tspan x="0">Deploy</tspan>
                                  </text>
                                </g>
                                <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={dragonCat}
                                ></image>
                                <image
                                  x="248"
                                  y="11"
                                  width="41"
                                  height="42"
                                  href={dragon}
                                ></image>

                                <image
                                  x="51"
                                  y="5"
                                  width="47"
                                  height="45"
                                  href={starActive}
                                  className="star-active"
                                ></image>
                                <image
                                  x="95"
                                  y="14"
                                  width="28"
                                  height="26"
                                  href={starInactive}
                                  className="inactStar"
                                ></image>
                                <image
                                  x="127"
                                  y="14"
                                  width="28"
                                  height="26"
                                  href={starInactive}
                                  className="inactStar"
                                ></image>
                                <image
                                  x="162"
                                  y="14"
                                  width="28"
                                  height="26"
                                  href={starInactive}
                                  className="inactStar"
                                ></image>

                                <text
                                  className="battle-id"
                                  transform="translate(284.123 85.706) scale(0.944 0.943)"
                                  text-anchor="end"
                                >
                                  {e.key}
                                </text>
                                <text
                                  className="battle-nm"
                                  transform="translate(18.55 85.985) scale(0.785 0.784)"
                                >
                                  {/*e.charName == 1
                                    ? "Freathes"
                                    : e.charName == 2
                                    ? "Jikiru"
                                    : e.charName == 3
                                    ? "OraLillystorm"
                                    : e.charName == 4
                                    ? "Asheal"
                                    : e.charName == 5
                                    ? "Rizgath"
                                  : "Ratcher"*/
                                  e.charName}
                                </text>

                                <text
                                  className="battle-pwr-lbl"
                                  transform="translate(21.611 404.051) scale(0.331 0.33)"
                                >
                                  Power:
                                </text>
                                <text
                                  className="battle-pwr-tx"
                                  transform="translate(87.291 404.374) scale(0.98)"
                                >
                                  {e.power}
                                </text>

                                <text
                                  className="battle-acr-lbl"
                                  transform="translate(168.806 403.081) scale(0.414 0.413)"
                                >
                                  Acurracy:
                                </text>
                                <text
                                  className="battle-acr-tx"
                                  transform="translate(258.791 404.374) scale(0.98)"
                                >
                                  {e.baseAccuracy}
                                </text>

                                <image
                                  x="12"
                                  y="8"
                                  width="39"
                                  height="43"
                                  href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                                ></image>
                              </svg>
                            </div>
                          </div>
                        );
                      })
                    ) : null
                  ) : makeItTrue && allCharacters ? (
                    allCharacters.map((e, i) => {
                      return (
                        <div>
                          <div className="battle-card">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="300"
                              height="513"
                              viewBox="0 0 300 513"
                            >
                              <path
                                className="pntNo battle-card-bg"
                                d="M12.565,1.9H287.833a10.552,10.552,0,0,1,10.541,10.562V471.538A10.552,10.552,0,0,1,287.833,482.1H12.565A10.552,10.552,0,0,1,2.024,471.538V12.462A10.551,10.551,0,0,1,12.565,1.9Z"
                              />
                              <g
                                class="btnG"
                                onClick={() => addToRoaster(e.id)}
                              >
                                <image
                                  x="55"
                                  y="457"
                                  width="191"
                                  height="56"
                                  href={buttonGreen}
                                ></image>
                                <text
                                  class="battle-btn-tx"
                                  transform="translate(148.102 490.361) scale(0.486 0.483)"
                                >
                                  <tspan x="0">Deploy</tspan>
                                </text>
                              </g>
                              {e.level == 1 ? (
                                <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={
                                    /*e.charName == 1
                                      ? cardMonster1
                                      : e.charName == 2
                                      ? cardMonster3
                                      : e.charName == 3
                                      ? cardMonster
                                      : e.charName == 4
                                      ? cardMonster4
                                      : e.charName == 5
                                      ? cardMonster5
                                      : cardBase*/
                                    charImages(e)
                                  }
                                ></image>
                              ) : e.level == 2 ? (
                                <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={
                                    /*e.charName == 1
                                      ? cardMonster1
                                      : e.charName == 2
                                      ? cardMonster3
                                      : e.charName == 3
                                      ? cardMonster
                                      : e.charName == 4
                                      ? cardMonster4
                                      : e.charName == 5
                                      ? cardMonster5
                                      : cardBase*/
                                    charImages(e)
                                  }
                                ></image>
                              ) : e.level == 3 ? (
                                <image
                                  x="30"
                                  y="118"
                                  width="232"
                                  height="218"
                                  href={
                                    /*e.charName == 1
                                      ? cardMonster1
                                      : e.charName == 2
                                      ? cardMonster3
                                      : e.charName == 3
                                      ? cardMonster
                                      : e.charName == 4
                                      ? cardMonster4
                                      : e.charName == 5
                                      ? cardMonster5
                                      : cardBase*/
                                    charImages(e)
                                  }
                                ></image>
                              ) : null}
                              <image
                                x="248"
                                y="11"
                                width="41"
                                height="42"
                                href={dragon}
                              ></image>

                              <image
                                x="51"
                                y="5"
                                width="47"
                                height="45"
                                href={starActive}
                                className="star-active"
                              ></image>
                              <image
                                x="95"
                                y="14"
                                width="28"
                                height="26"
                                href={starInactive}
                                className="inactStar"
                              ></image>
                              <image
                                x="127"
                                y="14"
                                width="28"
                                height="26"
                                href={starInactive}
                                className="inactStar"
                              ></image>
                              <image
                                x="162"
                                y="14"
                                width="28"
                                height="26"
                                href={starInactive}
                                className="inactStar"
                              ></image>

                              <text
                                className="battle-id"
                                transform="translate(284.123 85.706) scale(0.944 0.943)"
                                text-anchor="end"
                              >
                                {e.key}
                              </text>
                              <text
                                className="battle-nm"
                                transform="translate(18.55 85.985) scale(0.785 0.784)"
                              >
                                {/*e.charName == 1
                                  ? "Freathes"
                                  : e.charName == 2
                                  ? "Jikiru"
                                  : e.charName == 3
                                  ? "OraLillystorm"
                                  : e.charName == 4
                                  ? "Asheal"
                                  : e.charName == 5
                                  ? "Rizgath"
                                : "Ratcher"*/
                                e.charName}
                              </text>

                              <text
                                className="battle-pwr-lbl"
                                transform="translate(21.611 404.051) scale(0.331 0.33)"
                              >
                                Power:
                              </text>
                              <text
                                className="battle-pwr-tx"
                                transform="translate(87.291 404.374) scale(0.98)"
                              >
                                {e.power}
                              </text>

                              <text
                                className="battle-acr-lbl"
                                transform="translate(168.806 403.081) scale(0.414 0.413)"
                              >
                                Acurracy:
                              </text>
                              <text
                                className="battle-acr-tx"
                                transform="translate(258.791 404.374) scale(0.98)"
                              >
                                {e.baseAccuracy}
                              </text>

                              <image
                                x="12"
                                y="8"
                                width="39"
                                height="43"
                                href={e.skillRarity == 0 ? common : e.skillRarity == 1 ? uncommon : rare}
                              ></image>
                            </svg>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={3000} //3 secs
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Choosecharacter;
