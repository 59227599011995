import { VALID_CHAIN_TYPE } from '../../helpers/constant';
import * as types from "../actions/types";
import { provider } from 'web3-core';

export interface Wallet {
  connected: boolean,
  address: string,
  chain_id: number,
  valid_network: boolean,
  explorer: string,
  loader: string,
  provider: null
}

const initialState = {
  connected: false,
  address: "",
  chain_id: null,
  valid_network: false,
  explorer: "",
  loader: "",
  provider: null
};


interface payload {
  payload: any;
  type: string;
}


const WalletReducer = (state = initialState, payload: payload) => {
  switch (payload.type) {
    case types.WALLET_CONNECTED: {
      const { address, chain_id, loader, provider }: { address: string, chain_id: VALID_CHAIN_TYPE, loader: string, provider: provider } = payload.payload;
      return {
        ...state,
        connected: true,
        address: address,
        chain_id: chain_id,
        loader,
        // explorer: ChainIdToExplorer[`${chain_id}`],
        provider: provider
      };
    }

    case types.WALLET_DISCONNECTED: {
      return { ...state, connected: false };
    }

    case types.WALLET_ADDRESS_CHANGED: {
      const { address } = payload.payload;
      return {
        ...state,
        address: address,
      };
    }

    case types.WALLET_CHAIN_CHANGED: {
      const { chain_id } = payload.payload;

      return {
        ...state,
        chain_id: chain_id,
      };
    }

    case types.NETWORK_VALID: {
      return {
        ...state,
        valid_network: true,
      };
    }

    case types.NETWORK_INVALID: {
      return {
        ...state,
        valid_network: false,
      };
    }

    default:
      return state;
  }
};

export default WalletReducer;
