import cardMonster1 from "../assets/images/card-monster-1.png";
import cardMonster2 from "../assets/images/card-monster-3.png";
import cardMonster3 from "../assets/images/card-monster-3.png";
import cardMonster4 from "../assets/images/card-monster-4.png";
import cardMonster5 from "../assets/images/card-monster-5.png";
import cardMonster6 from "../assets/images/card-monster.png";
import cardMonster from "../assets/images/card-monster.png";

import asheal1 from "../assets/images/ash1.png";
import asheal2 from "../assets/images/ash2.png";
import asheal3 from "../assets/images/ash3.png";

import fraethes1 from "../assets/images/fraethes1.png";
import fraethes2 from "../assets/images/fraethes2.png";
import fraethes3 from "../assets/images/fraethes3.png";

import jikuru1 from "../assets/images/jikuru1.png";
import jikuru2 from "../assets/images/jikuru2.png";
import jikuru3 from "../assets/images/jikuru3.png";

import ora1 from "../assets/images/ora1.png";
import ora2 from "../assets/images/ora2.png";
import ora3 from "../assets/images/ora3.png";

import rat1 from "../assets/images/ratcher1.png";
import rat2 from "../assets/images/ratcher2.png";
import rat3 from "../assets/images/ratcher3.png";

import riz1 from "../assets/images/rizgath1.png";
import riz2 from "../assets/images/rizgath2.png";
import riz3 from "../assets/images/rizgath3.png";

import btuin from "../assets/images/btuin.png";

export const Battle_contract = "0x95C9abB09a61147936Bd791dAA91B1b88C16c868";
export const Character_contract = "0xD5d7d61E4AC33F85D4E809BF74a1C8E1E70A1A0e";
export const charImages = (e) => {
    var matrix = {
        "Asheal": {
            1: asheal1,
            2: asheal2,
            3: asheal3
        },
        "Fraethes": {
            1: fraethes1,
            2: fraethes2,
            3: fraethes3
        },
        "Jikuru": {
            1: jikuru1,
            2: jikuru2,
            3: jikuru3
        },
        "Ora Lillystorm": {
            1: ora1,
            2: ora2,
            3: ora3
        },
        "Ratcher": {
            1: rat1,
            2: rat2,
            3: rat3
        },
        "Riz’gath": {
            1: riz1,
            2: riz2,
            3: riz3
        },
        "B'tuin": {
            4: btuin
        }
    }
    return matrix[e.charName][e.level];
}