import React, { Component } from "react";
import "./App.css";
import "./style.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./views/Home/Combat";
import { Provider } from "react-redux";
import withRedux from "next-redux-wrapper";
import store from "../src/redux/store";
import Choosecharacter from "./views/Home/Roaster";

class App extends Component {
  render() {
    return (
      <div className="h-100">
        <Provider store={store}>
          <React.Fragment>
            <Router history={this.props.history}>
              <Route path="/" exact component={Home} />
              <Switch>
                <Route path="/roster" component={Choosecharacter} />
              </Switch>
            </Router>
          </React.Fragment>
        </Provider>
      </div>
    );
  }
}

//makeStore function that returns a new store for every request
const makeStore = () => store;

export default withRedux(makeStore)(App);
